/* Responsive Variables (using Sass) */
$break1:    "(max-width:500px)";
$break2:    "(min-width:500px) and (max-width:1004px)";
$break2open:"(min-width:500px)";
$break3:    "(min-width:1005px) and (max-width:1094px)";
$break3open:"(min-width:1005px)";
$break4:    "(min-width:1095px) and (max-width:1281px)";
$break4open:"(min-width:1095px)";
$break5open:"(min-width:1282px)";

$start-direction: left !default;
$end-direction:   right !default;


@mixin scroll{overflow-y:auto; scrollbar-width:thin; /*-webkit-overflow-scrolling:touch;*/}

/*scrolls*/
::-webkit-scrollbar {width:6px!important; height:6px!important; background-color:#f5f5f5;}
::-webkit-scrollbar-thumb {background-color:rgba(0,0,0,.2);}
::-webkit-scrollbar-track {background-color:rgba(255, 255, 255, 0.08);}   

/* colors */

.is-hide-break1{
    @media #{$break1}{display:none!important;}
  }
  .is-only-break1{
    @media #{$break2open}{display:none!important;}
  }

  
:root{
    --white:#fff;
    --black:#000;
    --grayA:#657786;
    --gray-light:#f8f5fa;
    --border-color:#e6ecf0;
    
    /* common blue */
    --base-color:203; /* base hue of blue*/
    --main-color:hsla(var(--base-color), 89%, 53%, 1);
    --hover-color:hsla(var(--base-color), 89%, 47%, 1);
    --bg-hover-color:hsla(var(--base-color), 89%, 53%, 0.1);
  }
/* Grid Layout */
.main-grid{
    display: grid; 
    justify-content: center; 
    min-height: 100vh; 
    margin: 0 auto;
  @media #{$break1}, #{$break2}{ 
       .main-side{ display: none; } 
      //  min-width: 400px;
      grid-template-columns: auto minmax(auto, 0px); 
    }
    @media #{$break2}{    
      .main-side{ display: none; } 
       grid-template-columns: auto minmax(auto, 600px); 
       max-width: 705px;
    }
    @media #{$break3open}{
       grid-template-columns: auto 600px minmax(290px, 350px);
    }
    @media #{$break4open}{ 
       max-width: 1235px; 
    }
  }

  
.main-header{
    position:-webkit-sticky;/* Safari */ position:sticky; z-index:2; top:0; background-color:var(--white);
    display:flex; height:50px; padding:0 15px; border-bottom:solid 1px var(--border-color);
    &-content{display:flex; height:100%; flex-direction:column; justify-content:center;}
    .icon-button{margin-top:auto; margin-bottom:auto;}
    .icon-back{margin-#{$end-direction}:10px; font-size:22px;}
  }
  .main-content{border:solid 0 var(--border-color); border-width:0 1px;}

  .main-nav{
@include scroll();
position:sticky; top:0; max-height:100vh; overflow:auto;
&-item{padding:7px 0;}
@media #{$break1}{
    position:fixed; z-index:4; #{$start-direction}:0; #{$end-direction}:0; bottom:0; top:auto;
    background-color:var(--white); border-top:solid 1px var(--border-color);
    .logo{display:none;}
    &-list{display:flex; justify-content:space-between; padding:0 15px;}
}
@media #{$break2}, #{$break3}, #{$break4}{
    width:60px; padding:0 10px; 
}
@media #{$break5open}{
    min-width:80px; width:100px; padding-#{$end-direction}:44px;  
}
}

.menu-item{
    display:flex; align-items:center; width:fit-content; padding:10px; border-radius:20px / 50%;
    font-weight:bold; font-size:2rem;
    .icon{
      width:26px; height:26px;
      line-height:26px; text-align:center; filter:grayscale(1) opacity(0.8); transition:0.2s;
      &-explore{filter:grayscale(1) opacity(1) brightness(0.5);}
    }
    .text{color:var(--black); padding-#{$end-direction}:30px; margin-#{$start-direction}:30px; }
    &:hover,
    &:hover, 
    &:focus,
    &.is-selected{
      .text{color:var(--main-color);}
      .icon{filter:grayscale(0.3) opacity(1);}
    }
     @media #{$break1}, #{$break2}, #{$break3}, #{$break4}{     
       .text{display:none;}
       .common-button{
         .text{display:none;}
       }
    }
  }
