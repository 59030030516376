#QuizBody {
    color: white;
    padding: 5%;
    font-family: Arial;
    text-align: center;
    font-size: xx-large;
  }

#ToggleButtonGroup {
    position: relative;
    bottom: 10%;
    text-align: center;
    margin-top: 5%;
    background-color: #282c34;
}

#ButtonNoSelect {
    position: relative;
    bottom: 10%;
    text-align: center;
    margin-top: 5%;
    background-color: whitesmoke;
    color: black;
}

#QuestionCard{
    margin: auto;
    align-items: center;
}